<template>
<transition name="loginTransition">
  <div v-if="closeLogin === '1' || closeLogin === '2'" class="loginGreenn">
    <div @click.prevent="closeLogins" class="close">
      X
    </div>
    <div class="icon">
      <img src="@/assets/img/loginLogo.png" alt="Login">
    </div>
    <div class="conten">
      <div class="text">
        <div class="title">
          <h2>{{ $t('client.text_81') }}</h2>
        </div>
          <transition name="fade">
            <div v-if="showLoginRegister === '2'">
              <LoginFormComponent />
            </div>
          </transition>
          <transition name="fade">
            <div v-if="showLoginRegister === '1'">
              <RegisterFormComponent />
            </div>
          </transition>
         <div class="account">
            <span v-if="showLoginRegister === '2'">
              <a @click="alterLoginRegister('1')">
                {{ $t('client.text_82') }}
              </a>
            </span>
            <span v-if="showLoginRegister === '1'">
              <a @click="alterLoginRegister('2')">
                {{ $t('client.text_83') }}
              </a>
            </span>
        </div>
      </div>
    </div>
  </div>
</transition>
</template>

<script>
import LoginFormComponent from '@/components/login/LoginFormComponent'
import RegisterFormComponent from '@/components/login/RegisterFormComponent'
export default {
  name: 'LoginComponent',
  components: {
    LoginFormComponent,
    RegisterFormComponent
  },
   data: () => ({
    showLoginRegister: null,
    closeLogin: null,
  }),
  mounted() {
    this.$root.$on('handlerLoginRegister', (id) => {
      this.closeLogin = id
      this.showLoginRegister = id
    })
  },
  methods: {
    alterLoginRegister(id)
    {
      this.showLoginRegister = id
    },
    closeLogins () {
      this.closeLogin = '0'
    }
  }
}
</script>

<style lang="scss">
.loginTransition-enter-active, .loginTransition-leave-active {
  transition: transform .5s, opacity 0.4s;
}
.loginTransition-enter, .loginTransition-leave-to {
  transform: translateY(-20%);
  opacity: 0;
}
.fade-enter-active, .fade-leave-active {
  transition: transform 1.0s, opacity 0.4s;
}
.fade-enter{
  transform: scale(0.5);
  position: absolute;
  opacity: 0;
}
.fade-leave-to{
  display: none;
}
.loginGreenn{
  background-color: hsla(199, 70%, 9%, 0.84);
  height: 100vh;
  .close{
    position: absolute;
    color: #FFF;
    cursor: pointer;
    margin-top: 20px;
    margin-left: 20px;
  }
  .icon{
    position: absolute;
    right: 0;
    padding-top: 10% ;
    padding-right: 10% ;
    img{
      top:0;
      float: right;
      width: 50px;
    }
  }
  .conten{
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    flex-direction: column;
  .text{
    padding: 0 10%;
    .title{
      h2{
        background: -webkit-linear-gradient(45deg, #008d95, #02d1a8);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        font-family: 'Montserrat';
        font-size: 38px;
        font-weight: 700;
      }
    }
    .form{
      input{
        border: 0 !important;
        padding: 0 !important;
        background: transparent;
        font-family: 'Montserrat' !important;
        font-weight: 600 !important;
        color: #FFF !important;
        height: 35px !important;
        border-bottom: 5px solid #FFF !important;
        margin-top: 35px !important;
        font-size: 16px !important;
        width: 100%;
      }
      input::-webkit-input-placeholder{
        color: #FFF;
        font-size: 16px !important;
        font-family: 'Montserrat';
        font-weight: 600;
      }
      .next{
        margin-top: 35px;
        button{
          background: #00ac69;
          color: #FFF;
          border: 0;
          padding: 10px 0;
          border-radius: 20px;
          font-family: 'Montserrat';
          font-weight: 600;
          text-decoration: none;
          width: 100%;
          &:hover{
            background: #02925b;
            transition-duration: 0.5s;
          }
        }
      }
    }
    .account{
      margin-top: 30px;
      text-align: center;
      span{
        color: #FFF;
        font-family: 'Montserrat';
        font-weight: 600;
        cursor: pointer;
        a:hover{
          text-decoration: underline;
        }
      }
    }
  }
}
}
@media only screen and (max-width: 450px) {
  .loginGreenn{
    .conten{
      .text{
        .title{
          text-align: center;
          h2{
            background: -webkit-linear-gradient(45deg, #008d95, #8fe93c);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            font-family: 'Montserrat';
            font-size: 30px;
            font-weight: 700;
          }
        }
      }
    }
  }
}
</style>